import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

export const generateSearchParams = (obj: any, tenant: string) => {
  const searchParams = new URLSearchParams(omitBy(obj, isEmpty));
  if (tenant.includes('so')) {
    searchParams.delete('zones');
  }
  return searchParams.toString();
};
