import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type ConfigState = {
  tenant: string;
  zones: string;
};
const initialState: ConfigState = {
  tenant: '',
  zones: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<ConfigState['tenant']>) => {
      state.tenant = action.payload;
    },
    setZones: (state, action: PayloadAction<ConfigState['zones']>) => {
      state.zones = action.payload;
    },
  },
});

//Export actions
export const { setTenant, setZones } = configSlice.actions;

//Definición de selectores
export const getTenant = (state: RootState) => state.config.tenant;
export const getZones = (state: RootState) => state.config.zones;

export default configSlice.reducer;
