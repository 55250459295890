import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchMeasurementLimits } from '../../api/fetchMeasures';
import { createAppAsyncThunk } from '../../app/hooks';
import { RootState } from '../../app/store';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';
import { IValidationRulesForMeasurementSettings } from '../../models/IValidationRulesForMeasurementSettings';
import get from 'lodash/get';
import { fetchStoreUrl } from '../../api/fetchStoreUrl';
import { findClosestLargestMeasure } from '../../utils/facets';

export type MeasurementSettingsReducer = {
  windowHeight: string;
  windowWidth: string;
  windowHeightWithError: boolean;
  windowWidthWithError: boolean;
  validationRulesStatus: IStatusAsyncThunk;
  validationRules: IValidationRulesForMeasurementSettings | null;
  redirectStoreStatus: IStatusAsyncThunk;
  informationHowToMeasureModalIsShowing: boolean;
  formIsValid: boolean;
  widthValues: string[]
};

const initialState: MeasurementSettingsReducer = {
  windowHeight: '',
  windowWidth: '',
  windowHeightWithError: false,
  windowWidthWithError: false,

  validationRulesStatus: 'idle',
  validationRules: null,
  redirectStoreStatus: 'idle',

  informationHowToMeasureModalIsShowing: false,
  formIsValid: false,
  widthValues: []
};

type fetchAsyncProps = {
  tenant: string;
  zones?: string;
};

export const fetchCurtainMeasurementValidationRulesAsync = createAppAsyncThunk(
  'curtainSelection/fetchCurtainMeasurementValidationRules',
  async ({ tenant, zones }: fetchAsyncProps, thunkAPI) => {
    const {
      selectedCurtainStyle,
      selectedCurtainDesign,
      selectedCurtainCollection,
      selectedCurtainColor,
    } = thunkAPI.getState().curtainSelection;
    const style = get(selectedCurtainStyle, 'attribute.value', '');
    const design = get(selectedCurtainDesign, 'attribute.value', '');
    const collection = get(selectedCurtainCollection, 'attribute.value', '');
    const color = get(selectedCurtainColor, 'attribute.value', '');
    const res = await fetchMeasurementLimits({ style, design, collection, color, tenant, zones });
    return res;
  }
);

export const fetchRedirectStoreAsync = createAppAsyncThunk(
  'curtainSelection/fetchRedirectStore',
  async ({ tenant, zones }: fetchAsyncProps, thunkAPI) => {
    const {
      curtainSelection: {
        selectedCurtainStyle,
        selectedCurtainDesign,
        selectedCurtainCollection,
        selectedCurtainColor,
      },
      measurementSettings: { windowHeight, windowWidth, widthValues },
    } = thunkAPI.getState();
    const style = get(selectedCurtainStyle, 'attribute.value', '');
    const design = get(selectedCurtainDesign, 'attribute.value', '');
    const collection = get(selectedCurtainCollection, 'attribute.value', '');
    const color = get(selectedCurtainColor, 'attribute.value', '');
    const maximumWidth = findClosestLargestMeasure(widthValues, parseInt(windowWidth))
    const res = await fetchStoreUrl({
      style,
      design,
      collection,
      color,
      width: windowWidth,
      height: windowHeight,
      tenant,
      zones,
      maxWidth: maximumWidth.toString(),
    });
    window.parent.location.href = res;
    return res;
  }
);

export const measurementSettingsSlice = createSlice({
  name: 'measurementSettings',
  initialState,
  reducers: {
    setFormIsValid: (state, action: PayloadAction<MeasurementSettingsReducer['formIsValid']>) => {
      state.formIsValid = action.payload;
    },
    setWindowHeight: (state, action: PayloadAction<MeasurementSettingsReducer['windowHeight']>) => {
      state.windowHeight = action.payload;
    },
    setWindowWidth: (state, action: PayloadAction<MeasurementSettingsReducer['windowWidth']>) => {
      state.windowWidth = action.payload;
    },
    setWindowHeightWithError: (
      state,
      action: PayloadAction<MeasurementSettingsReducer['windowHeightWithError']>
    ) => {
      state.windowHeightWithError = action.payload;
    },
    setWindowWidthWithError: (
      state,
      action: PayloadAction<MeasurementSettingsReducer['windowWidthWithError']>
    ) => {
      state.windowWidthWithError = action.payload;
    },
    clearValidationRules: (state) => {
      state.validationRules = null;
    },
    showInformationHowToMeasureModal: (state) => {
      state.informationHowToMeasureModalIsShowing = true;
    },
    hideInformationHowToMeasureModal: (state) => {
      state.informationHowToMeasureModalIsShowing = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurtainMeasurementValidationRulesAsync.pending, (state) => {
      state.validationRulesStatus = 'loading';
    });
    builder.addCase(fetchCurtainMeasurementValidationRulesAsync.fulfilled, (state, action: any) => {
      state.validationRulesStatus = 'idle';
      state.validationRules = action.payload;
      state.widthValues = action.payload.widthValues
    });
    builder.addCase(fetchRedirectStoreAsync.pending, (state) => {
      state.redirectStoreStatus = 'loading';
    });
    builder.addCase(fetchRedirectStoreAsync.fulfilled, (state) => {
      state.redirectStoreStatus = 'idle';
    });
  },
});

export const {
  setFormIsValid,
  setWindowHeight,
  setWindowWidth,
  setWindowHeightWithError,
  setWindowWidthWithError,
  clearValidationRules,
  showInformationHowToMeasureModal,
  hideInformationHowToMeasureModal,
} = measurementSettingsSlice.actions;

export const getFormIsValid = (state: RootState) => state.measurementSettings.formIsValid;

export const getWindowHeight = (state: RootState) => state.measurementSettings.windowHeight;

export const getWindowWidth = (state: RootState) => state.measurementSettings.windowWidth;

export const getValidationRules = (state: RootState) => state.measurementSettings.validationRules;

export const getInformationHowToMeasureModalIsShowing = (state: RootState) =>
  state.measurementSettings.informationHowToMeasureModalIsShowing;

export const getCurtainValidationRulesStatus = (state: RootState) =>
  state.measurementSettings.validationRulesStatus;

export const getRedirectStoreStatus = (state: RootState) =>
  state.measurementSettings.redirectStoreStatus;

export const getWindowHeightWithError = (state: RootState) =>
  state.measurementSettings.windowHeightWithError;

export const getWindowWidthWithError = (state: RootState) =>
  state.measurementSettings.windowWidthWithError;

export default measurementSettingsSlice.reducer;
