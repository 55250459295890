import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import ActionsBarMobile, { ActionBarMobile } from '../../../../components/ActionsBarMobile';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { PillButton, Spinner } from '@digital-retail/journey-ui-kit';
import { OptionsListStyled, LoadingStyled } from './styles';
import { setStep } from '../../../../redux/CurtainDesignSelectionSlice';
import { nextPosition, previousPosition } from '../../../../redux/StepperSlice';
import { COLORS } from '../../../../utils/constants/colors';
import {
  fetchCurtainDesignsAsync,
  fetchCurtainCollectionsAsync,
  getCurtainDesigns,
  getCurtainDesignStatus,
  getCurtainCollectionStatus,
  getSelectedCurtainDesign,
  selectCurtainDesign,
} from '../../../../redux/CurtainSelectionSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import { getTenant, getZones } from '../../../../redux/ConfigSlice';
import { ICurtainDesign } from '../../../../models/ICurtainFacets';

const CurtainDesignPickerMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const types = useAppSelector(getCurtainDesigns);
  const selectedCurtainDesign = useAppSelector(getSelectedCurtainDesign);
  const curtainDesignStatus = useAppSelector(getCurtainDesignStatus);
  const curtainCollectionStatus = useAppSelector(getCurtainCollectionStatus);
  const tenant = useAppSelector(getTenant);
  const zones = useAppSelector(getZones);

  useEffect(() => {
    setDigitalData();
    if (types.length === 0) {
      dispatch(fetchCurtainDesignsAsync({ tenant, zones })).then((res) => {
        if (res.payload?.length === 0) {
          dispatch(nextPosition());
        }
      });
    }
  }, []);

  const handleClickPreviousPosition = () => {
    dispatch(previousPosition());
  };

  const handleClick = (typeSelected: ICurtainDesign) => {
    dispatch(selectCurtainDesign(typeSelected));
  };

  const handleClickConfirmCurtainType = async () => {
    const resultAction = await dispatch(fetchCurtainCollectionsAsync({ tenant, zones }));
    const collections = unwrapResult(resultAction);
    if (collections.length === 0) {
      dispatch(nextPosition());
    } else {
      dispatch(setStep('curtainCollectionSelection'));
    }
  };

  return (
    <div data-testid="curtain-design-picker-mobile">
      {(curtainDesignStatus === 'loading' || curtainCollectionStatus === 'loading') && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainDesignStatus === 'idle' && curtainCollectionStatus === 'idle' && (
        <OptionsListStyled data-testid="options-list">
          {types.map((type) => (
            <PillButton
              key={type.id}
              className={type.id === selectedCurtainDesign?.id ? 'active' : ''}
              active={type.id === selectedCurtainDesign?.id}
              size="sm"
              onClick={() => handleClick(type)}
            >
              {type.label[0].toUpperCase()}
              {type.label.substring(1)}
            </PillButton>
          ))}
        </OptionsListStyled>
      )}
      <ActionsBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickConfirmCurtainType}
            data-testid="forward"
            variant="ghost"
            iconPosition="end"
            Icon={RightArrow}
            disabled={!selectedCurtainDesign}
          >
            Confirmar
          </PillButton>
        </ActionBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickPreviousPosition}
            data-testid="backward"
            variant="ghost"
            iconPosition="start"
            Icon={LeftArrow}
            style={{
              color: COLORS.gray20,
            }}
          >
            Selecciona un estilo
          </PillButton>
        </ActionBarMobile>
      </ActionsBarMobile>
    </div>
  );
};

export default CurtainDesignPickerMobile;
