import get from 'lodash/get';

export const findClosestLargestMeasure = (
    values: string[],
    measure: number,
  ): string => {
    let closestQuery = get(values, "[0]", 0);
    let closetVal = parseInt(closestQuery);
    for (const value of values) {
      const val = parseInt(value);
      if (val >= measure && closetVal < measure) {
        closetVal = val;
        closestQuery= value
      }
      if (val >= measure && val <= closetVal) {
        if( Math.abs(val - measure) < Math.abs(closetVal - measure)){
          closetVal= val;
          closestQuery=value;
        }
      }
    }
    return closestQuery;
  };