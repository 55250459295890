/* eslint-disable no-empty */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCollectionOptions } from '../../api/fetchCollections';
import { fetchColorOptions } from '../../api/fetchColors';
import { fetchDesignOptions } from '../../api/fetchDesigns';
import { fetchStyleOptions } from '../../api/fetchStyles';
import { createAppAsyncThunk } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  ICurtainCollection,
  ICurtainColor,
  ICurtainDesign,
  ICurtainStyle,
} from '../../models/ICurtainFacets';
import get from 'lodash/get';
import { IStatusAsyncThunk } from '../../models/IStatusAsyncThunk';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';

export type CurtainSelectionReducer = {
  curtainStyleStatus: IStatusAsyncThunk;
  curtainStylesList: ICurtainStyle[];
  selectedCurtainStyle: ICurtainStyle | null;

  curtainDesignStatus: IStatusAsyncThunk;
  curtainDesignList: ICurtainDesign[];
  selectedCurtainDesign: ICurtainDesign | null;

  curtainCollectionStatus: IStatusAsyncThunk;
  curtainCollectionList: ICurtainCollection[];
  selectedCurtainCollection: ICurtainCollection | null;

  curtainColorStatus: IStatusAsyncThunk;
  curtainColorsList: ICurtainColor[];
  selectedCurtainColor: ICurtainColor | null;
};

const initialState: CurtainSelectionReducer = {
  curtainStyleStatus: 'idle',
  curtainStylesList: [],
  selectedCurtainStyle: null,

  curtainDesignStatus: 'idle',
  curtainDesignList: [],
  selectedCurtainDesign: null,

  curtainCollectionStatus: 'idle',
  curtainCollectionList: [],
  selectedCurtainCollection: null,

  curtainColorStatus: 'idle',
  curtainColorsList: [],
  selectedCurtainColor: null,
};

type fetchAsyncProps = {
  tenant: string;
  zones?: string;
};

export const fetchCurtainStylesAsync = createAppAsyncThunk(
  'curtainSelection/fetchCurtainStyles',
  async ({ tenant, zones }: fetchAsyncProps) => {
    const res = await fetchStyleOptions({ tenant, zones });
    return res;
  }
);

export const fetchCurtainDesignsAsync = createAppAsyncThunk(
  'curtainSelection/fetchCurtainDesigns',
  async ({ tenant, zones }: fetchAsyncProps, thunkApi) => {
    const { selectedCurtainStyle } = thunkApi.getState().curtainSelection;
    const style = get(selectedCurtainStyle, 'attribute.value', '');
    const res = await fetchDesignOptions({ tenant, zones, style });
    return res;
  }
);

export const fetchCurtainCollectionsAsync = createAppAsyncThunk(
  'curtainSelection/fetchCurtainCollections',
  async ({ tenant, zones }: fetchAsyncProps, thunkApi) => {
    const { selectedCurtainStyle, selectedCurtainDesign } = thunkApi.getState().curtainSelection;
    const style = get(selectedCurtainStyle, 'attribute.value', '');
    const design = get(selectedCurtainDesign, 'attribute.value', '');
    const res = await fetchCollectionOptions({ tenant, style, zones, design });
    return res;
  }
);

export const fetchCurtainColorsAsync = createAppAsyncThunk(
  'curtainSelection/fetchCurtainColors',
  async ({ tenant, zones }: fetchAsyncProps, thunkApi) => {
    const { selectedCurtainStyle, selectedCurtainDesign, selectedCurtainCollection } =
      thunkApi.getState().curtainSelection;
    const style = get(selectedCurtainStyle, 'attribute.value', '');
    const design = get(selectedCurtainDesign, 'attribute.value', '');
    const collection = get(selectedCurtainCollection, 'attribute.value', '');
    const res = await fetchColorOptions({ tenant, style, design, zones, collection });
    return res;
  }
);

const size = () => {
  const width = window.innerWidth;
  return width && width > BREAKPOINTS.tablet ? 'desktop' : 'mobile';
};

export const curtainSelectionSlice = createSlice({
  name: 'curtainSelection',
  initialState,
  reducers: {
    selectCurtainStyle: (
      state,
      action: PayloadAction<CurtainSelectionReducer['selectedCurtainStyle']>
    ) => {
      state.selectedCurtainStyle = action.payload;
      state.curtainDesignList = [];
      state.selectedCurtainDesign = null;
      state.curtainColorsList = [];
    },
    selectCurtainDesign: (
      state,
      action: PayloadAction<CurtainSelectionReducer['selectedCurtainDesign']>
    ) => {
      state.selectedCurtainDesign = action.payload;
      state.curtainCollectionList = [];
      state.selectedCurtainCollection = null;
      state.curtainColorsList = [];
      state.selectedCurtainColor = null;
    },
    selectCurtainCollection: (
      state,
      action: PayloadAction<CurtainSelectionReducer['selectedCurtainCollection']>
    ) => {
      state.selectedCurtainCollection = action.payload;
      state.selectedCurtainColor = null;
      state.curtainColorsList = [];
    },
    selectCurtainColor: (
      state,
      action: PayloadAction<CurtainSelectionReducer['selectedCurtainColor']>
    ) => {
      state.selectedCurtainColor = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurtainStylesAsync.pending, (state) => {
      state.curtainStyleStatus = 'loading';
    });
    builder.addCase(fetchCurtainStylesAsync.fulfilled, (state, action: any) => {
      state.curtainStyleStatus = 'idle';
      state.curtainStylesList = action.payload;
      try {
        state.curtainStylesList &&
          state.curtainStylesList.map((style) => {
            const newImage = new Image();
            newImage.src = style.image[size()];
            window[style.image[size()]] = newImage;
            if (size() == 'desktop') {
              const newThumbImage = new Image();
              newThumbImage.src = style.image.thumb;
              window[style.image.thumb] = newThumbImage;
            }
          });
      } catch (error) {}
    });
    builder.addCase(fetchCurtainDesignsAsync.pending, (state) => {
      state.curtainDesignStatus = 'loading';
    });
    builder.addCase(fetchCurtainDesignsAsync.fulfilled, (state, action: any) => {
      state.curtainDesignStatus = 'idle';
      state.curtainDesignList = action.payload;
      try {
        state.curtainDesignList &&
          state.curtainDesignList.map((img) => {
            const newImage = new Image();
            newImage.src = img.image[size()];
            window[img.image[size()]] = newImage;
          });
      } catch (error) {}
    });
    builder.addCase(fetchCurtainCollectionsAsync.pending, (state) => {
      state.curtainCollectionStatus = 'loading';
    });
    builder.addCase(fetchCurtainCollectionsAsync.fulfilled, (state, action: any) => {
      state.curtainCollectionStatus = 'idle';
      state.curtainCollectionList = action.payload;
      try {
        state.curtainCollectionList &&
          state.curtainCollectionList.map((img) => {
            const newImage = new Image();
            newImage.src = img.image[size()];
            window[img.image[size()]] = newImage;
          });
      } catch (error) {}
    });
    builder.addCase(fetchCurtainColorsAsync.pending, (state) => {
      state.curtainColorStatus = 'loading';
    });
    builder.addCase(fetchCurtainColorsAsync.fulfilled, (state, action: any) => {
      state.curtainColorStatus = 'idle';
      state.curtainColorsList = action.payload;
    });
  },
});

export const {
  selectCurtainStyle,
  selectCurtainDesign,
  selectCurtainCollection,
  selectCurtainColor,
} = curtainSelectionSlice.actions;

export const getCurtainsStatus = (state: RootState) => state.curtainSelection.curtainStyleStatus;

export const getCurtainDesignStatus = (state: RootState) =>
  state.curtainSelection.curtainDesignStatus;

export const getCurtainCollectionStatus = (state: RootState) =>
  state.curtainSelection.curtainCollectionStatus;

export const getSelectedCurtainStyle = (state: RootState) =>
  state.curtainSelection.selectedCurtainStyle;

export const getSelectedCurtainDesign = (state: RootState) =>
  state.curtainSelection.selectedCurtainDesign;

export const getSelectedCurtainCollection = (state: RootState) =>
  state.curtainSelection.selectedCurtainCollection;

export const getSelectedCurtainColor = (state: RootState) =>
  state.curtainSelection.selectedCurtainColor;

export const getCurtainStyles = (state: RootState) => state.curtainSelection.curtainStylesList;

export const getCurtainDesigns = (state: RootState) => state.curtainSelection.curtainDesignList;

export const getCurtainCollections = (state: RootState) =>
  state.curtainSelection.curtainCollectionList;

export const getCurtainColorStatus = (state: RootState) =>
  state.curtainSelection.curtainColorStatus;

export const getCurtainColors = (state: RootState) => state.curtainSelection.curtainColorsList;

export default curtainSelectionSlice.reducer;
