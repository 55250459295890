import React, { useEffect } from 'react';
import MediaComponent from '../../../../components/MediaComponent';
import {
  FieldsetStyled,
  ImageStyled,
  IndicationInputStyled,
  LabelStyled,
  MeasurementSettingFormStyled,
  TitleStyled,
  WindowHeightStyled,
  WindowWidthStyled,
  LoadingStyled,
  HelperStyled,
} from './styles';
import { Input, Spinner } from '@digital-retail/journey-ui-kit';
import { ReactComponent as IndicatorA } from '../../../../assets/icons/indicador-A.svg';
import { ReactComponent as IndicatorB } from '../../../../assets/icons/indicador-B.svg';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  fetchCurtainMeasurementValidationRulesAsync,
  getCurtainValidationRulesStatus,
  getRedirectStoreStatus,
  getValidationRules,
  getWindowHeight,
  getWindowHeightWithError,
  getWindowWidth,
  getWindowWidthWithError,
  setFormIsValid,
  setWindowHeight,
  setWindowHeightWithError,
  setWindowWidth,
  setWindowWidthWithError,
  showInformationHowToMeasureModal,
} from '../../../../redux/MeasurementSettingsSlice';
import { formValidation, minAndMaxValidation } from './utils';
import { ReactComponent as QuestionIcon } from '../../../../assets/icons/question-icon.svg';
import ventanaAB from '../../../../assets/images/MeasurementSettings/img-ventana-a+b.png';
import BreadcrumbCurtain from '../../../../components/BreadcrumbCurtain';
import { getSelectedCurtainStyle } from '../../../../redux/CurtainSelectionSlice';
import { getTenant, getZones } from '../../../../redux/ConfigSlice';

const MeasurementSettingForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const validationRulesStatus = useAppSelector(getCurtainValidationRulesStatus);
  const redirectStoreStatus = useAppSelector(getRedirectStoreStatus);
  const tenant = useAppSelector(getTenant);
  const zones = useAppSelector(getZones);

  const validationRules = useAppSelector(getValidationRules);

  const windowHeight = useAppSelector(getWindowHeight);
  const windowWidth = useAppSelector(getWindowWidth);
  const windowHeightWithError = useAppSelector(getWindowHeightWithError);
  const windowWidthWithError = useAppSelector(getWindowWidthWithError);

  const handleChangeWindowHeightInput = (value: string) => dispatch(setWindowHeight(value));
  const handleChangeWindowWidthInput = (value: string) => dispatch(setWindowWidth(value));
  const handleClickHelper = () => dispatch(showInformationHowToMeasureModal());

  useEffect(() => {
    if (validationRules === null) {
      dispatch(fetchCurtainMeasurementValidationRulesAsync({ tenant, zones }));
    }
  }, []);

  useEffect(() => {
    if (validationRules)
      dispatch(
        setWindowHeightWithError(
          !minAndMaxValidation({
            valueInFloat: parseFloat(windowHeight || ''),
            ...validationRules.windowHeight,
          })
        )
      );
  }, [windowHeight]);

  useEffect(() => {
    dispatch(
      setFormIsValid(
        formValidation({
          windowHeight: windowHeight,
          windowWidth: windowWidth,
          windowHeightWithError,
          windowWidthWithError,
        })
      )
    );
  });

  useEffect(() => {
    if (validationRules)
      dispatch(
        setWindowWidthWithError(
          !minAndMaxValidation({
            valueInFloat: parseFloat(windowWidth || ''),
            ...validationRules.windowWidth,
          })
        )
      );
  }, [windowWidth]);

  return (
    <MeasurementSettingFormStyled data-testid="measurement-setting-form">
      <MediaComponent from="tablet">
        <BreadcrumbCurtain />
      </MediaComponent>
      <TitleStyled>Ingresa las medidas de tu ventana</TitleStyled>
      {(validationRulesStatus === 'loading' || redirectStoreStatus === 'loading') && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {redirectStoreStatus === 'idle' && validationRules !== null && (
        <>
          <ImageStyled src={ventanaAB} />
          <FieldsetStyled>
            <WindowHeightStyled>
              <LabelStyled>
                <IndicatorA />
                <span>Alto (cm)</span>
              </LabelStyled>
              <Input
                error={windowHeightWithError}
                data-testid="window-height-input"
                placeholder={`Ej. ${validationRules.windowHeight.max}`}
                value={windowHeight?.toString() || ''}
                type="decimal"
                onChange={handleChangeWindowHeightInput}
                inputMode="numeric"
              />
              <IndicationInputStyled>
                {validationRules.windowWidth.min > 0 &&
                  `Mínimo ${validationRules.windowHeight.min} cm, `}
                Máximo {validationRules.windowHeight.max} cm.
              </IndicationInputStyled>
            </WindowHeightStyled>
            <WindowWidthStyled>
              <LabelStyled>
                <IndicatorB />
                <span>Ancho (cm)</span>
              </LabelStyled>
              <Input
                placeholder={`Ej. ${validationRules.windowWidth.max}`}
                data-testid="window-width-input"
                error={windowWidthWithError}
                value={windowWidth?.toString() || ''}
                type="decimal"
                onChange={handleChangeWindowWidthInput}
                inputMode="numeric"
              />
              <IndicationInputStyled>
                {validationRules.windowWidth.min > 0 &&
                  `Mínimo ${validationRules.windowWidth.min} cm, `}
                Máximo {validationRules.windowWidth.max} cm.
              </IndicationInputStyled>
            </WindowWidthStyled>
          </FieldsetStyled>
          <MediaComponent from="tablet">
            <HelperStyled data-testid="helper" onClick={handleClickHelper}>
              <span>Cómo medir tus {selectedCurtainStyle?.pluralLabel}</span>
              <QuestionIcon />
            </HelperStyled>
          </MediaComponent>
        </>
      )}
    </MeasurementSettingFormStyled>
  );
};

export default MeasurementSettingForm;
