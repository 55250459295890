import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  LabelStyled,
  OptionNameStyled,
  OptionsListStyled,
  OptionStyled,
  ThumbnailStyled,
  TitleStyled,
  LoadingStyled,
} from './styles';
import { Button, Spinner } from '@digital-retail/journey-ui-kit';
import { nextPosition, previousPosition } from '../../../../redux/StepperSlice';
import {
  fetchCurtainDesignsAsync,
  fetchCurtainCollectionsAsync,
  getCurtainDesigns,
  getCurtainDesignStatus,
  getCurtainCollectionStatus,
  getSelectedCurtainStyle,
  getSelectedCurtainDesign,
  selectCurtainDesign,
} from '../../../../redux/CurtainSelectionSlice';
import { setStep } from '../../../../redux/CurtainDesignSelectionSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import Box from '../../../../components/Box';
import ActionsBarDesktop from '../../../../components/ActionsBarDesktop';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { COLORS } from '../../../../utils/constants/colors';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { getTenant, getZones } from '../../../../redux/ConfigSlice';
import { ICurtainDesign } from '../../../../models/ICurtainFacets';

const CurtainDesignPickerDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const selectedCurtainDesign = useAppSelector(getSelectedCurtainDesign);
  const designs = useAppSelector(getCurtainDesigns);
  const curtainDesignStatus = useAppSelector(getCurtainDesignStatus);
  const curtainCollectionStatus = useAppSelector(getCurtainCollectionStatus);
  const tenant = useAppSelector(getTenant);
  const zones = useAppSelector(getZones);

  useEffect(() => {
    setDigitalData();
    if (designs.length === 0) {
      dispatch(fetchCurtainDesignsAsync({ tenant, zones })).then((res) => {
        if (res.payload?.length === 0) {
          dispatch(nextPosition());
        }
      });
    }
  }, []);

  const handleClickForward = async () => {
    if (selectedCurtainStyle && selectedCurtainDesign) {
      const resultAction = await dispatch(fetchCurtainCollectionsAsync({ tenant, zones }));
      const collectionsUpdate = unwrapResult(resultAction);
      if (collectionsUpdate.length === 0) {
        dispatch(nextPosition());
      } else {
        dispatch(setStep('curtainCollectionSelection'));
      }
    }
  };

  const handleClickBackward = () => {
    dispatch(previousPosition());
  };

  const handleClickOption = (typeSelected: ICurtainDesign) => {
    dispatch(selectCurtainDesign(typeSelected));
  };

  return (
    <div data-testid="curtain-design-picker-desktop">
      <TitleStyled>{selectedCurtainStyle?.label} a medida</TitleStyled>
      <LabelStyled>Selecciona el diseño de cortina</LabelStyled>
      {(curtainDesignStatus === 'loading' || curtainCollectionStatus === 'loading') && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainDesignStatus === 'idle' && curtainCollectionStatus === 'idle' && (
        <Box maxHeight="340px">
          <OptionsListStyled data-testid="options-list">
            {designs.map((design) => (
              <OptionStyled
                key={design.id}
                className={design.id === selectedCurtainDesign?.id ? 'active' : ''}
                onClick={() => handleClickOption(design)}
              >
                <ThumbnailStyled src={design.image.thumb} alt={design.label} />
                <OptionNameStyled>{design.label}</OptionNameStyled>
              </OptionStyled>
            ))}
          </OptionsListStyled>
        </Box>
      )}
      <ActionsBarDesktop>
        <Button
          data-testid="forward"
          onClick={handleClickForward}
          disabled={selectedCurtainDesign === null}
        >
          Continuar
        </Button>
        <PillButton
          onClick={handleClickBackward}
          data-testid="backward"
          variant="ghost"
          iconPosition="start"
          Icon={LeftArrow}
          iconStyle={{
            fill: COLORS.primary,
          }}
          style={{
            color: COLORS.primary,
            marginTop: 12,
            paddingLeft: 0,
          }}
        >
          Selecciona un estilo
        </PillButton>
      </ActionsBarDesktop>
    </div>
  );
};

export default CurtainDesignPickerDesktop;
