import {
  ColorIndicatorStyled,
  ColorNameStyled,
  CurtainColorPickerMobileStyled,
  LoadingStyled,
  OptionsListStyled,
  OptionStyled,
  TitleStyled,
} from './styles';
import { Spinner } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  fetchCurtainColorsAsync,
  getCurtainColors,
  getSelectedCurtainColor,
  getCurtainColorStatus,
  selectCurtainColor,
} from '../../../../redux/CurtainSelectionSlice';
import BreadcrumbCurtain from '../../../../components/BreadcrumbCurtain';
import MediaComponent from '../../../../components/MediaComponent';
import CurtainColorSelectionActionsBarMobile from '../CurtainColorSelectionActionsBarMobile';
import CurtainColorSelectionActionsBarDesktop from '../CurtainColorSelectionActionsBarDesktop';
import { useEffect } from 'react';
import { clearValidationRules } from '../../../../redux/MeasurementSettingsSlice';
import Box from '../../../../components/Box';
import { getTenant, getZones } from '../../../../redux/ConfigSlice';
import { ICurtainColor } from '../../../../models/ICurtainFacets';
import { nextPosition } from '../../../../redux/StepperSlice';

export type ColorIndicatorStyledProps = {
  color: string;
};

const CurtainColorPicker: React.FC = () => {
  const dispatch = useAppDispatch();

  const curtainColorsList = useAppSelector(getCurtainColors);
  const curtainColorSelected = useAppSelector(getSelectedCurtainColor);
  const curtainColorStatus = useAppSelector(getCurtainColorStatus);
  const tenant = useAppSelector(getTenant);
  const zones = useAppSelector(getZones);

  useEffect(() => {
    dispatch(fetchCurtainColorsAsync({ tenant, zones })).then((res) => {
      if (res.payload?.length === 0) {
        dispatch(nextPosition());
      }
    });
  }, []);

  const handleClick = (colorSelected: ICurtainColor) => {
    dispatch(selectCurtainColor(colorSelected));
    dispatch(clearValidationRules());
  };

  return (
    <CurtainColorPickerMobileStyled data-testid="curtain-color-picker">
      <MediaComponent from="tablet">
        <BreadcrumbCurtain />
        <TitleStyled>Selecciona el color que más te guste</TitleStyled>
      </MediaComponent>
      {curtainColorStatus === 'loading' && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainColorStatus === 'idle' && (
        <Box maxHeight="340px">
          <OptionsListStyled data-testid="options-list">
            {curtainColorsList.map((color) => (
              <OptionStyled
                key={color.id}
                className={`${color.id === curtainColorSelected?.id ? 'active' : ''}`}
                onClick={() => handleClick(color)}
              >
                <ColorIndicatorStyled color={color.color} />
                <ColorNameStyled>
                  {color.label[0].toUpperCase()}
                  {color.label.substring(1)}
                </ColorNameStyled>
              </OptionStyled>
            ))}
          </OptionsListStyled>
        </Box>
      )}

      <MediaComponent from="mobile" to="tablet">
        <CurtainColorSelectionActionsBarMobile />
      </MediaComponent>
      <MediaComponent from="tablet">
        <CurtainColorSelectionActionsBarDesktop />
      </MediaComponent>
    </CurtainColorPickerMobileStyled>
  );
};

export default CurtainColorPicker;
